@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar:horizontal {
    height: 6px;
}

::-webkit-scrollbar-thumb {
    @apply bg-gray-400 rounded-lg
}

::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-500 rounded-lg
}

::-webkit-scrollbar-track {
    position: absolute;
    background: transparent;
}

.ant-btn-primary {
    background: #dc4955;
    background-color: #dc4955;
}

.ant-table-header.ant-table-sticky-holder {
    overflow: visible !important;
}

.ant-switch:not(.ant-switch-disabled) {
    background: rgba(0, 0, 0, 0.45);
}

.ant-switch.ant-switch-checked:not(.ant-switch-disabled) {
    background: #dc4955;
}

span.ant-upload.ant-upload-btn {
    padding: 0px !important;
}

.ant-upload.ant-upload-drag {
    border: none;
}

.ant-upload-drag-container {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ant-modal-footer {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
}

.ant-popconfirm-buttons {
    display: flex;
    flex-direction: row-reverse;
}